<style>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0;
}
</style>

<template>
  <div>
    <p class="title">分类</p>
    <Row :gutter="8">
      <i-col span="24">
        <RadioGroup v-model="type" type="button" size="small">
          <Radio v-for="item in confirmTypes" :key="item.id" :label="item.id">{{
            item.name
          }}</Radio>
        </RadioGroup>
      </i-col>
    </Row>

    <p class="title">工单状态</p>
    <Row :gutter="8">
      <i-col span="24">
        <RadioGroup v-model="status" type="button" size="small">
          <Radio
            v-for="item in confirmStatus"
            :key="item.id"
            :label="item.id"
            >{{ item.name }}</Radio
          >
        </RadioGroup>
      </i-col>
    </Row>
    <p class="title">账单状态</p>
    <Row :gutter="8">
      <i-col span="24">
        <RadioGroup v-model="chargeStatus" type="button" size="small">
          <Radio
            v-for="item in chargeStatusArray"
            :key="item.id"
            :label="item.id"
            >{{ item.name }}</Radio
          >
        </RadioGroup>
      </i-col>
    </Row>

    <p class="title p-t-5">作业类型</p>
    <Row :gutter="8">
      <i-col span="24">
        <div
          v-for="(item, index) in taskitemTypeArray"
          :key="index"
          class="p-l-10 p-1-10 m-b-2"
          @click="changeChecked('taskitemType', item.taskitemType)"
          :class="
            chkTaskitemTypes.includes(item.taskitemType)
              ? 'workplatform-radio-button-active'
              : 'workplatform-radio-button'
          "
        >
          {{ item.taskitemTypeText }}
        </div>
      </i-col>
    </Row>

    <p class="title p-t-5">资源类型</p>
    <Row :gutter="8">
      <i-col span="24">
        <div
          v-for="(item, index) in resourceTypeArray"
          :key="index"
          class="p-l-10 p-1-10 m-b-2"
          @click="changeChecked('resourcetype', item.resourcetypeId)"
          :class="
            chkResourceTypes.includes(item.resourcetypeId)
              ? 'workplatform-radio-button-active'
              : 'workplatform-radio-button'
          "
        >
          {{ item.resourcetypeText }}
        </div>
      </i-col>
    </Row>
    <template v-if="(type===1)">
      <p class="title p-t-5">用途</p>
    <Row>
      <!-- <i-col span="24">
            <RadioGroup v-model="adType" size="small" type="button">
            <Radio label="-1">全部</Radio>
                <Radio v-for="type in orderadTypes" :key="type.value" :label="type.value">{{type.name}}</Radio>
            </RadioGroup>

        </i-col> -->
      <i-col span="24">
        <div
          v-for="(item, index) in orderadTypes"
          :key="index"
          class="p-l-10 p-1-10 m-b-2"
          @click="changeChecked('adType', item.value)"
          :class="
            chkadTypes.includes(item.value)
              ? 'workplatform-radio-button-active'
              : 'workplatform-radio-button'
          "
        >
          {{ item.name }}
        </div>
      </i-col>
    </Row>
    </template>

    <p class="title p-t-5">时间类型</p>
    <Row>
      <i-col span="24">
        <RadioGroup v-model="timeCategory" size="small" type="button">
          <Radio
            v-for="timeCategory in timecategorys"
            :key="timeCategory.timeCategory"
            :label="timeCategory.timeCategory"
            >{{ timeCategory.timeName }}</Radio
          >
        </RadioGroup>
      </i-col>
    </Row>
    <p class="title p-t-5">时间区间</p>
    <Row :gutter="8">
      <i-col span="12" class="p-b-5">
        <DatePicker
          placeholder="开始时间"
          v-model="startDate"
          size="small"
          transfer
        ></DatePicker>
      </i-col>
      <i-col span="12" class="p-b-5">
        <DatePicker
          placeholder="结束时间"
          size="small"
          v-model="endDate"
          placement="bottom-end"
        ></DatePicker>
      </i-col>
    </Row>

    <p class="title p-t-5">结算公司</p>
    <Row :gutter="8">
      <i-col span="24">
        <Select v-model="currentsettlementCompanyId" size="small">
          <Option value="-1">全部</Option>
          <Option
            v-for="(company, index) in settlementcompanyArray"
            :key="index"
            :value="company.companyId"
            >{{ company.companyName }}</Option
          >
        </Select>
      </i-col>
    </Row>

    <p class="title p-t-5">供应商</p>
    <Row :gutter="8">
      <i-col span="24">
        <Select v-model="currentsupplierCompanyId" size="small">
          <Option value="-1">全部</Option>
          <Option
            v-for="(company, index) in suppliercompanyArray"
            :key="index"
            :value="company.companyId"
            >{{ company.companyName }}</Option
          >
        </Select>
      </i-col>
    </Row>

    <p class="title p-t-5">计费区间</p>
    <Row :gutter="8">
      <i-col span="12" class="p-b-5">
        <i-input
          type="number"
          size="small"
          v-model="minCost"
          clearable
          placeholder="最低计费金额"
        ></i-input>
      </i-col>
      <i-col span="12" class="p-b-5">
        <i-input
          type="number"
          size="small"
          v-model="maxCost"
          clearable
          placeholder="最高计费金额"
        ></i-input>
      </i-col>
    </Row>

    <p class="title p-t-5">关键字</p>
    <Row :gutter="8">
      <i-col span="18">
        <i-input
          type="text"
          size="small"
          v-model="keyword"
          clearable
          placeholder="如：客户、品牌、编码"
        ></i-input>
      </i-col>
      <i-col span="6">
        <Button
          type="primary"
          icon="ios-search"
          long
          size="small"
          :loading="loadSearch"
          @click="searchmaintenanceReconciliationList"
        >
          <span v-if="!loadSearch">搜索</span>
          <span v-else>加载中...</span>
        </Button>
      </i-col>
    </Row>
  </div>
</template>

<script>
import {
  gettaskitemtypelist,
  getresourcetypelist,
  getsettlements,
  getsuppliers,
  gettimecategory
} from '@/api/msp/settlement'
import { getOrderAdType } from '@/api/msp/status'

import { ParseDate } from '@/utils/dateFormat'

export default {
  data () {
    return {
      confirmStatus: [
        { id: -1, name: '全部' },
        { id: 1, name: '已派单' },
        { id: 2, name: '执行中' },
        { id: 3, name: '已完成' },
        { id: 4, name: '已质检验收' }
      ],
      status: -1,

      chargeStatusArray: [
        { id: -1, name: '全部' },
        { id: 1, name: '未对账' },
        { id: 2, name: '已对账' }
      ],
      chargeStatus: -1,
      confirmTypes: [
        { id: 1, name: '履约账单' },
        { id: 2, name: '运维账单' }
      ],
      type: 1,

      loadSearch: false,
      taskitemTypeArray: [], // 作业类型集合
      taskitemTypeId: null,
      chkTaskitemTypes: [-1],

      resourceTypeArray: [], // 资源类型集合
      resourceTypeId: null,
      chkResourceTypes: [-1],

      timecategorys: [],
      timeCategory: null,

      orderadTypes: [],
      adType: '-1',
      chkadTypes: [-1],

      startDate: '',
      endDate: '',
      keyword: '',
      minCost: null,
      maxCost: null,

      settlementcompanyArray: [], // 结算方公司清单
      suppliercompanyArray: [], // 供应商清单
      currentsupplierCompanyId: '-1',
      currentsettlementCompanyId: '-1'
    }
  },
  created () {
    this.inittaskitemTypeArray()
    this.initTimecategory()
    this.initOrderAdType()
  },
  methods: {
    initTimecategory () {
      const that = this
      gettimecategory({}).then((res) => {
        if (res.length) {
          that.timeCategory = res[0].timeCategory
        }
        that.timecategorys = res
      })
    },
    initOrderAdType () {
      const that = this
      getOrderAdType({
        type: 1
      }).then((res) => {
        that.orderadTypes = [
          {
            value: -1,
            name: '全部',
            type: -1
          }
        ]
        that.orderadTypes = that.orderadTypes.concat(res)
      })
    },

    // 加载作业类型
    inittaskitemTypeArray () {
      const that = this
      that.taskitemTypeArray = []

      gettaskitemtypelist({
        settlementStatus: that.status === -1 ? null : that.status,
        taskMainTypeFlag: that.type
      }).then((res) => {
        that.taskitemTypeArray = res
        that.initresourceTypeArray()
      })
    },
    // 变更多选框选择
    changeChecked (key, value) {
      const that = this
      switch (key) {
        case 'taskitemType':
          if (value === -1) {
            that.chkTaskitemTypes = [-1]
          } else {
            const index = that.chkTaskitemTypes.indexOf(-1)
            if (index > -1) {
              that.chkTaskitemTypes.splice(index, 1)
            }

            if (that.chkTaskitemTypes.includes(value)) {
              const index1 = that.chkTaskitemTypes.indexOf(value)
              if (index1 > -1) {
                that.chkTaskitemTypes.splice(index1, 1)
              }
            } else {
              that.chkTaskitemTypes.push(value)
            }
          }
          that.initresourceTypeArray()
          break
        case 'resourcetype':
          if (value === -1) {
            that.chkResourceTypes = [-1]
          } else {
            const index = that.chkResourceTypes.indexOf(-1)
            if (index > -1) {
              that.chkResourceTypes.splice(index, 1)
            }

            if (that.chkResourceTypes.includes(value)) {
              const index1 = that.chkResourceTypes.indexOf(value)
              if (index1 > -1) {
                that.chkResourceTypes.splice(index1, 1)
              }
            } else {
              that.chkResourceTypes.push(value)
            }
          }
          break
        case 'adType':
          if (value === -1) {
            that.chkadTypes = [-1]
          } else {
            const index = that.chkadTypes.indexOf(-1)
            if (index > -1) {
              that.chkadTypes.splice(index, 1)
            }

            if (that.chkadTypes.includes(value)) {
              const index1 = that.chkadTypes.indexOf(value)
              if (index1 > -1) {
                that.chkadTypes.splice(index1, 1)
              }
            } else {
              that.chkadTypes.push(value)
            }
          }
          break
      }
    },
    // 加载资源类型
    initresourceTypeArray () {
      const that = this
      that.resourceTypeArray = []
      let taskitemTypeStr = null
      if (that.chkTaskitemTypes.length && !that.chkTaskitemTypes.includes(-1)) {
        taskitemTypeStr = that.chkTaskitemTypes.toString()
      }
      getresourcetypelist({
        settlementStatus: that.status === -1 ? null : that.status,
        taskMainTypeFlag: that.type,
        taskitemTypes: taskitemTypeStr // that.taskitemTypeId === -1 ? null : that.taskitemTypeId
      }).then((res) => {
        that.resourceTypeArray = res
        that.resourceTypeId = that.resourceTypeArray[0].resourcetypeId

        this.initsettlementCompanyArray()
      })
    },
    // 获取结算方公司列表
    initsettlementCompanyArray () {
      getsettlements({}).then((res) => {
        this.settlementcompanyArray = res
        // if (this.settlementcompanyArray.length) {
        //   this.currentsettlementCompanyId = this.settlementcompanyArray[0].companyId
        // }
        this.inisupplierCompanyArray()
      })
    },
    // 获取供应商公司列表
    inisupplierCompanyArray () {
      getsuppliers({}).then((res) => {
        this.suppliercompanyArray = res
        // if (this.suppliercompanyArray.length) {
        //   this.currentsupplierCompanyId = this.suppliercompanyArray[0].companyId
        // }
        this.searchmaintenanceReconciliationList()
      })
    },

    // 搜索列表
    searchmaintenanceReconciliationList () {
      var query = {
        settlementCompanyId: this.currentsettlementCompanyId,
        supplierCompanyId: this.currentsupplierCompanyId,
        status: this.status, // 对账状态
        chargeStatus: this.chargeStatus,
        taskMainTypeFlag: this.type,
        taskitemType: this.taskitemTypeId,
        resourcetypeId: this.resourceTypeId,
        timeCategory: this.timeCategory,
        startDate: this.startDate ? ParseDate(this.startDate) : '',
        endDate: this.endDate ? ParseDate(this.endDate) : ''
      }

      if (this.keyword) {
        query.keyword = this.keyword
      }
      if (this.minCost || this.minCost === 0) {
        query.minCost = this.minCost
      }
      if (this.maxCost || this.maxCost === 0) {
        query.maxCost = this.maxCost
      }
      if (this.chkTaskitemTypes.length && !this.chkTaskitemTypes.includes(-1)) {
        query.taskitemTypes = this.chkTaskitemTypes.toString()
      }
      if (this.chkResourceTypes.length && !this.chkResourceTypes.includes(-1)) {
        query.resourcetypeIds = this.chkResourceTypes.toString()
      }
      // if (parseInt(this.adType) !== -1) {
      //   query.adType = this.adType
      // }
      if (this.chkadTypes.length && !this.chkadTypes.includes(-1)) {
        query.adTypes = this.chkadTypes.toString()
      }

      // if (this.executeStatus !== -1) { query.executeStatus = this.executeStatus }
      this.$store.commit(
        'set_reconciliationDemo_maintenanceReconciliationQuery',
        query
      )
      this.$store.commit('set_reconciliationDemo_update', new Date())
    }
  },
  computed: {
    companyId () {
      return this.$store.getters.token.userInfo.companyId
    },
    publisherId () {
      return this.$store.getters.token.userInfo.publisherId
    }
  },
  watch: {
    status () {
      this.inittaskitemTypeArray()
    },
    type () {
      this.inittaskitemTypeArray()
    }
  }
}
</script>
